*
{
    margin: 0;
    padding: 0;
}
html,
body
{
    position: relative;
}
body
{
    background-color: #020222;
}
.experience
{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}
.text
{
    color: #fff;
    font-family: Roboto, sans-serif;
    background: linear-gradient(180deg, rgba(255,255,255,0.06) 0%, rgba(0,0,0,0.06) 100%);
    padding: 15px;
    width: 50vw;
    margin-left: 25vw;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: -5px -5px 5px 1px rgb(0, 242, 234), 5px 5px 5px 1px rgb(255, 0, 79);
}
.text a{
    color: #fff;
    text-decoration: underline;
}
.wrapper
{
    position: relative;
    text-align: center;
    overflow: hidden;
}
/* ::-webkit-scrollbar-button
{
    visibility: hidden;
} */